<template>
  <div class="rays-tuner-member">
    <div class="member-top">
      <div class="member-title">成员管理</div>
      <div class="member-top-tools">
        <a-input
          placeholder="可输入ID/用户名关键词"
          v-model:value="searchName"
          @keyup.enter="searchUser"
          class="top-input"
        >
          <template #prefix>
            <SearchOutlined />
          </template>
        </a-input>
        <a-button @click="allDelete" danger style="border-radius: 4px"
          >批量删除</a-button
        >
        <a-button @click="addMember" class="add-button">新增成员</a-button>
        <a-modal
          v-model:visible="showModal.showAdd"
          width="400px"
          class="add_modal"
        >
          <template #title>
            <span style="color: #222222; font-weight: 500; margin-left: 15px"
              >新增成员</span
            >
          </template>
          <a-form
            layout="inline"
            ref="formRef"
            :rules="rules"
            :model="formState"
          >
            <a-form-item class="modal_item" name="name">
              <div style="color: #666666; margin-bottom: 4px">用户名</div>
              <a-input
                v-model:value="formState.name"
                placeholder="请输入用户名"
              />
            </a-form-item>
            <a-form-item class="modal_item" name="email">
              <div style="color: #666666; margin-bottom: 4px">邮箱</div>
              <a-input
                v-model:value="formState.email"
                placeholder="请输入邮箱"
              />
            </a-form-item>
            <a-form-item class="modal_item" name="password">
              <div style="color: #666666; margin-bottom: 4px">密码</div>
              <a-input
                v-model:value="formState.password"
                type="password"
                autocomplete="off"
                placeholder="请输入密码"
              />
            </a-form-item>
          </a-form>
          <template #footer>
            <a-button
              @click="saveBtn"
              :class="{ onedit: btnActive }"
              class="save_btn"
              >保存</a-button
            >
            <a-button @click="cancelBtn" class="cancel_btn">取消</a-button>
          </template>
        </a-modal>
      </div>
    </div>
    <div class="member-body">
      <div class="member-body-list" v-if="showList">
        <a-table
          :row-selection="rowSelection"
          :columns="memberColumns"
          :data-source="memberData"
          :scroll="{ x: '1200px', y: 'calc(100vh - 240px)' }"
          :pagination="paginationMember"
        >
          <template #operation="{ record }">
            <a @click="limitClick(record)" class="operation">权限</a>
            <a-modal
              v-model:visible="showModal.showLimit[record.key]"
              width="640px"
              class="limit_modal"
            >
              <template #title>
                <span style="color: #222222; font-weight: 600">权限</span>
              </template>
              <div>
                <div style="margin-bottom: 28px">
                  <div
                    style="color: #666666; font-size: 14px; margin-bottom: 8px"
                  >
                    Seat
                    <span style="color: #aaaaaa; font-size: 12px">(剩余0)</span>
                  </div>
                  <a-input
                    v-model:value="seatNumber"
                    @change="inputClick"
                    placeholder="请输入分配的Seat数量"
                    style="width: 224px; height: 40px; border-radius: 4px"
                  />
                  <span
                    v-show="showModal.seatTip"
                    style="color: #ff2222; margin-left: 16px"
                    >请输入1-10的数值</span
                  >
                </div>
                <div style="margin-bottom: 16px">
                  <div
                    style="color: #666666; font-size: 14px; margin-bottom: 12px"
                  >
                    分配设备
                  </div>
                  <div
                    v-for="(item, index) in equipmentNum"
                    :key="item"
                    style="margin-bottom: 16px"
                  >
                    <a-select
                      v-model:value="selectState.type"
                      placeholder="手机型号"
                      size="large"
                      :options="userOptions"
                    />
                    <a-select
                      v-model:value="selectState.serial"
                      placeholder="序列号"
                      size="large"
                      :options="userOptions"
                    />
                    <span
                      v-if="showModal.showDelete"
                      @click="delEquip(index)"
                      class="equipment-delete"
                      ><DeleteOutlined style="font-size: 22px"
                    /></span>
                  </div>
                </div>
                <div style="margin-bottom: 20px">
                  <a-button @click="addEquipment" class="add-equipment-btn">
                    <template #icon><PlusOutlined /></template>
                    添加设备
                  </a-button>
                </div>
              </div>
              <template #footer>
                <a-button @click="limitCancel(record)" class="cancel_btn"
                  >取消</a-button
                >
                <a-button @click="limitSave(record)" class="edit_save_btn"
                  >保存</a-button
                >
              </template>
            </a-modal>
            <a @click="editClick(record)" class="operation">编辑</a>
            <a-modal
              v-model:visible="showModal.showEdit[record.key]"
              width="400px"
              class="add_modal"
            >
              <template #title>
                <span
                  style="color: #222222; font-weight: 600; margin-left: 15px"
                  >编辑成员</span
                >
              </template>
              <a-form
                layout="inline"
                ref="formRef"
                :rules="rules"
                :model="editState"
              >
                <a-form-item class="modal_item" name="name">
                  <div style="color: #666666; margin-bottom: 4px">用户名</div>
                  <a-input
                    v-model:value="editState.name"
                    placeholder="请输入用户名"
                  />
                </a-form-item>
                <a-form-item class="modal_item" name="email">
                  <div style="color: #666666; margin-bottom: 4px">邮箱</div>
                  <a-input
                    v-model:value="editState.email"
                    placeholder="请输入邮箱"
                  />
                </a-form-item>
                <a-form-item class="modal_item" name="password">
                  <div style="color: #666666; margin-bottom: 4px">密码</div>
                  <a-input
                    v-model:value="editState.password"
                    type="password"
                    autocomplete="off"
                    placeholder="请输入密码"
                  />
                </a-form-item>
              </a-form>
              <template #footer>
                <a-button @click="editSave(record)" class="edit_save_btn"
                  >保存</a-button
                >
                <a-button @click="editCancel(record)" class="cancel_btn"
                  >取消</a-button
                >
              </template>
            </a-modal>
            <a @click="deleteClick" class="operation">删除</a>
          </template>
        </a-table>
      </div>
      <div class="member-body-empty" v-else>
        <div class="body-empty">暂无成员</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  createVNode,
  reactive,
  onMounted,
  onBeforeUnmount,
  computed,
} from 'vue';
import {
  SearchOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons-vue';
import { Modal, message } from 'ant-design-vue';
import PerfectScrollbar from 'perfect-scrollbar';

const memberColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '10%',
  },
  {
    title: '用户名',
    dataIndex: 'name',
    key: 'name',
    width: '15%',
  },
  {
    title: '邮箱',
    dataIndex: 'email',
    key: 'email',
    width: '25%',
  },
  {
    title: '注册时间',
    dataIndex: 'regTime',
    key: 'regTime',
    width: '15%',
  },
  {
    title: 'Seat',
    dataIndex: 'seat',
    key: 'seat',
    width: '10%',
  },
  {
    title: '设备数',
    dataIndex: 'number',
    key: 'number',
    width: '10%',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    width: '15%',
    slots: { customRender: 'operation' },
  },
];
const memberData = [
  {
    key: '1',
    id: '12342',
    name: '我是用户名',
    email: 'bnjaibiudajsdna@raysengine.com',
    regTime: '2021-11-01 23:23:23',
    seat: '100',
    number: '9999',
  },
  {
    key: '2',
    id: '12342',
    name: '我是用户名',
    email: 'bnjaibiudajsdna@raysengine.com',
    regTime: '2021-11-01 23:23:23',
    seat: '100',
    number: '9999',
  },
  {
    key: '3',
    id: '12342',
    name: '我是用户名',
    email: 'bnjaibiudajsdna@raysengine.com',
    regTime: '2021-11-01 23:23:23',
    seat: '100',
    number: '9999',
  },
  {
    key: '4',
    id: '12342',
    name: '我是用户名',
    email: 'bnjaibiudajsdna@raysengine.com',
    regTime: '2021-11-01 23:23:23',
    seat: '100',
    number: '9999',
  },
  {
    key: '5',
    id: '12342',
    name: '我是用户名',
    email: 'bnjaibiudajsdna@raysengine.com',
    regTime: '2021-11-01 23:23:23',
    seat: '100',
    number: '9999',
  },
  {
    key: '6',
    id: '12342',
    name: '我是用户名',
    email: 'bnjaibiudajsdna@raysengine.com',
    regTime: '2021-11-01 23:23:23',
    seat: '100',
    number: '9999',
  },
  {
    key: '7',
    id: '12342',
    name: '我是用户名',
    email: 'bnjaibiudajsdna@raysengine.com',
    regTime: '2021-11-01 23:23:23',
    seat: '100',
    number: '9999',
  },
  {
    key: '8',
    id: '12342',
    name: '我是用户名',
    email: 'bnjaibiudajsdna@raysengine.com',
    regTime: '2021-11-01 23:23:23',
    seat: '100',
    number: '9999',
  },
  {
    key: '9',
    id: '12342',
    name: '我是用户名',
    email: 'bnjaibiudajsdna@raysengine.com',
    regTime: '2021-11-01 23:23:23',
    seat: '100',
    number: '9999',
  },
  {
    key: '10',
    id: '12342',
    name: '我是用户名',
    email: 'bnjaibiudajsdna@raysengine.com',
    regTime: '2021-11-01 23:23:23',
    seat: '100',
    number: '9999',
  },
  {
    key: '11',
    id: '12342',
    name: '我是用户名',
    email: 'bnjaibiudajsdna@raysengine.com',
    regTime: '2021-11-01 23:23:23',
    seat: '100',
    number: '9999',
  },
  {
    key: '12',
    id: '12342',
    name: '我是用户名',
    email: 'bnjaibiudajsdna@raysengine.com',
    regTime: '2021-11-01 23:23:23',
    seat: '100',
    number: '9999',
  },
  {
    key: '13',
    id: '12342',
    name: '我是用户名',
    email: 'bnjaibiudajsdna@raysengine.com',
    regTime: '2021-11-01 23:23:23',
    seat: '100',
    number: '9999',
  },
  {
    key: '14',
    id: '12342',
    name: '我是用户名',
    email: 'bnjaibiudajsdna@raysengine.com',
    regTime: '2021-11-01 23:23:23',
    seat: '100',
    number: '9999',
  },
];
export default defineComponent({
  name: 'MemberManage',
  components: {
    SearchOutlined,
    PlusOutlined,
    DeleteOutlined,
  },
  setup() {
    const searchName = ref('');
    const seatNumber = ref('');
    const showList = ref(true);
    const equipmentNum = ref([1]);
    const showModal = reactive({
      showAdd: false,
      showLimit: [],
      showEdit: [],
      seatTip: false,
      showDelete: false,
    });
    const paginationMember = {
      size: 'small',
      showQuickJumper: true,
      position: 'bottom',
      pageSize: 12,
      hideOnSinglePage: true,
      onChange: (page) => {
        console.log(page);
      },
    };
    const btnActive = ref(false);
    const formRef = ref();
    const formState = reactive({
      name: '',
      email: '',
      password: '',
    });
    const editState = reactive({
      name: '',
      email: '',
      password: '',
    });
    const selectState = reactive({
      type: undefined,
      serial: undefined,
    });
    let validateEdit = async (rule, value) => {
      console.log('validateEdit', value, value === '');
      if (value === '') {
        btnActive.value = false;
      } else {
        btnActive.value = true;
      }
      return Promise.resolve();
    };
    const rules = {
      name: [
        { required: true, message: '输入的名称不能为空', trigger: 'blur' },
        { required: true, message: '输入的名称不能为空', trigger: 'change' },
        { validator: validateEdit, trigger: 'change' },
      ],
      email: [
        { required: true, message: '输入的邮箱地址不能为空', trigger: 'blur' },
        {
          required: true,
          message: '输入的邮箱地址不能为空',
          trigger: 'change',
        },
        { type: 'email', message: '请输入有效的邮箱地址', trigger: 'change' },
        { type: 'email', message: '请输入有效的邮箱地址', trigger: 'blur' },
        { validator: validateEdit, trigger: 'change' },
      ],
      password: [
        { required: true, message: '输入的密码不能为空', trigger: 'blur' },
        { required: true, message: '输入的密码不能为空', trigger: 'change' },
        { validator: validateEdit, trigger: 'change' },
      ],
    };

    const searchUser = () => {
      console.log(searchName.value);
    };
    const allDelete = () => {
      console.log('delete:', selectedRowKeys.value);
      if (selectedRowKeys.value.length === 0) {
        message.warning('请先选择成员！');
      }
    };
    const addMember = () => {
      showModal.showAdd = true;
    };
    const saveBtn = () => {
      showModal.showAdd = false;
    };
    const cancelBtn = () => {
      showModal.showAdd = false;
      showModal.showDelete = false;
    };

    const limitClick = (record) => {
      showModal.showLimit[record.key] = true;
    };
    const addEquipment = () => {
      equipmentNum.value.push(1);
      showModal.showDelete = true;
    };
    const delEquip = (index) => {
      console.log(index);
      equipmentNum.value.splice(index, 1);
      if (equipmentNum.value.length > 1) {
        showModal.showDelete = true;
      } else {
        showModal.showDelete = false;
      }
    };
    const userOptions = ref([]);
    for (let i = 0; i < 5; i++) {
      userOptions.value.push({
        value: 'HUAWEI-TL00',
        label: 'HUAWEI-TL00',
      });
    }
    const inputClick = () => {
      console.log(seatNumber.value);
      if (
        isNaN(seatNumber.value) ||
        seatNumber.value < 1 ||
        seatNumber.value > 10 ||
        seatNumber.value % 1 !== 0
      ) {
        showModal.seatTip = true;
      } else {
        showModal.seatTip = false;
      }
    };
    const limitCancel = (record) => {
      showModal.showLimit[record.key] = false;
      equipmentNum.value = [1];
    };
    const limitSave = (record) => {
      showModal.showLimit[record.key] = false;
    };

    const editClick = (record) => {
      console.log(record);
      showModal.showEdit[record.key] = true;
      editState.name = record.name;
      editState.email = record.email;
    };
    const editCancel = (record) => {
      showModal.showEdit[record.key] = false;
    };
    const editSave = (record) => {
      showModal.showEdit[record.key] = false;
    };
    const deleteClick = () => {
      Modal.confirm({
        title: () => '确定要删除所选成员吗？',
        icon: createVNode(ExclamationCircleOutlined),
        okText: '删除',
        okType: 'danger',
        onOk() {
          console.log('ok');
        },
      });
    };

    const selectedRowKeys = ref([]);
    const onSelectChange = (changableRowKeys) => {
      console.log('selectedRowKeys changed: ', changableRowKeys);
      // if (selectedRowKeys.length < memberData.length) {
      //   selectAll.value = false;
      // }
      selectedRowKeys.value = changableRowKeys;
    };
    const rowSelection = computed(() => {
      return {
        selectedRowKeys: selectedRowKeys.value,
        onChange: onSelectChange,
        hideDefaultSelections: true,
        selections: [
          {
            key: 'all',
            text: '全选所有',
            onSelect: () => {
              for (let i = 0; i < memberData.length; i++) {
                selectedRowKeys.value.push(memberData[i].key);
              }
              // console.log('selectedRowKeysAll:', selectedRowKeys.value)
            },
          },
          {
            key: 'page',
            text: '全选当前页',
            onSelect: (record) => {
              selectedRowKeys.value = record;
              // console.log(record);
            },
          },
        ],
      };
    });

    // const rowSelection = {
    //   onChange: (selectedRowKeys, selectedRows) => {
    //     console.log('selectedRowKeys',selectedRowKeys);
    //     console.log('selectedRows: ', selectedRows);
    //   },
    //   onSelect: (record, selected, selectedRows) => {
    //     console.log(record, selected, selectedRows);
    //   },
    //   onSelectAll: (selected, selectedRows, changeRows) => {
    //     console.log(selected, selectedRows, changeRows);
    //   },
    // };

    let memberListDOM = null;
    let memberListPs = null;
    onMounted(() => {
      memberListDOM = document.querySelector('.ant-table-body');
      if (memberListDOM) {
        memberListPs = new PerfectScrollbar(memberListDOM);
      }
    });
    onBeforeUnmount(() => {
      if (memberListPs) {
        memberListPs.destroy();
        memberListPs = null;
      }
      memberListDOM = null;
    });

    return {
      memberColumns,
      memberData,
      searchName,
      seatNumber,
      showList,
      equipmentNum,
      showModal,
      paginationMember,
      btnActive,
      formRef,
      formState,
      editState,
      selectState,
      rules,
      searchUser,
      allDelete,
      addMember,
      saveBtn,
      cancelBtn,
      // selections,
      rowSelection,
      limitClick,
      addEquipment,
      delEquip,
      userOptions,
      editClick,
      deleteClick,
      inputClick,
      limitCancel,
      limitSave,
      editSave,
      editCancel,
    };
  },
});
</script>

<style lang="scss" scoped>
.rays-tuner-member {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.member-top {
  width: 100%;
  height: 72px;
  border-bottom: 1px solid #d8d8d8;
  padding: 0 40px;
}
.member-title {
  float: left;
  width: 120px;
  height: 72px;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Semibold;
  font-weight: 600;
  text-align: LEFT;
  color: #222222;
  line-height: 72px;
}
.member-top-tools {
  width: 545px;
  height: 72px;
  float: right;
  padding: 20px 0;
}
.top-input {
  width: 320px;
  height: 32px;
  margin-right: 24px;
}
.ant-input-affix-wrapper:hover {
  border-color: #604bdc;
}
.ant-input-affix-wrapper:focus {
  border-color: #604bdc;
  box-shadow: 0 0 0 2px rgba(96, 75, 220, 0.2);
}
.add-button {
  margin-left: 24px;
  color: #ffffff;
  border-radius: 4px;
  background-color: #604bdc;
}
.add-button:hover {
  border-color: #604bdc;
}
.add-button:focus {
  border-color: #604bdc;
  box-shadow: 0 0 0 2px rgba(136, 119, 255, 0.2);
}
.add_modal input {
  width: 320px;
  height: 40px;
  border-radius: 4px;
}
.add_modal .modal_item {
  margin-bottom: 20px;
}
.ant-input {
  &:hover {
    border-color: #604bdc;
  }
  &:focus {
    border-color: #604bdc;
    box-shadow: 0 0 0 2px rgba(96, 75, 220, 0.2);
  }
}
.add_modal button {
  width: 320px;
  height: 48px;
  border-radius: 4px;
  margin-bottom: 16px;
}
.edit_save_btn {
  border-color: #604bdc;
  background: #604bdc;
  color: #ffffff;
}
.add_modal .save_btn {
  background-color: #d8d8d8;
  color: #ffffff;
}
.add_modal,
.save_btn:hover {
  border-color: #d8d8d8;
}
.onedit {
  border-color: #604bdc !important;
  background: #604bdc !important;
}
.add_modal .cancel_btn {
  background-color: #fafafa;
  color: #3c3c3c;
}
.add_modal,
.limit_modal .cancel_btn:hover {
  border-color: #d9d9d9;
}
.limit_modal .cancel_btn {
  width: 60px;
  height: 32px;
  color: #3c3c3c;
  box-shadow: 0px 2px 0px 1px rgba(0, 0, 0, 0.01600000075995922);
  border-radius: 4px;
}
.limit_modal .edit_save_btn {
  width: 88px;
  height: 32px;
  background: #d8d8d8;
  color: #ffffff;
  border-color: #d8d8d8;
  box-shadow: 0px 2px 0px 1px rgba(0, 0, 0, 0.0430000014603138);
  border-radius: 4px;
}
.limit_modal .edit_save_btn:hover {
  border-color: #d8d8d8;
}
.equipment-delete {
  display: inline-block;
  border-radius: 4px;
  padding: 8px 10px;
  color: #aaaaaa;
}
.equipment-delete:hover {
  color: #ffffff;
  background-color: #ff2222;
}
.add-equipment-btn {
  width: 120px;
  height: 32px;
  background: rgba(96, 75, 220, 0.10000000149011612);
  border-radius: 4px;
  color: #604bdc;
}
.add-equipment-btn {
  &:hover {
    border-color: #604bdc;
  }
  &:focus {
    border-color: #604bdc;
    box-shadow: 0 0 0 2px rgba(96, 75, 220, 0.2);
  }
}
.member-body-list {
  padding: 0 40px;
}
.member-body-list :deep(.ant-table-thead) > tr > th {
  background-color: #ffffff !important;
  font-size: 12px;
  color: #aaaaaa;
}
.member-body-list :deep(.ant-table-body) {
  color: #3c3c3c;
  font-weight: 400;
}

.operation {
  color: #604bdc;
  margin-right: 24px;
}
.body-empty {
  width: 56px;
  height: 22px;
  margin: 400px auto;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #aaaaaa;
}
</style>
<style>
.limit_modal .ant-select {
  width: 224px;
  border-radius: 4px;
  margin-right: 16px;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #604bdc;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #604bdc;
  box-shadow: 0 0 0 2px rgba(96, 75, 220, 0.2);
}
.limit_modal .ant-modal-header {
  border-bottom: 0;
}
.limit_modal .ant-modal-footer {
  padding: 20px 40px;
}
.add_modal .ant-modal-header {
  border-bottom: 0;
}
.add_modal .ant-modal-body {
  padding: 24px 40px;
}
.add_modal .ant-modal-footer {
  border-top: 0;
  padding: 10px 40px;
}
</style>
